import { Box, Card, IconButton } from "@mui/material";
import FlexBox from "./flexbox/FlexBox";
import { H6, Paragraph } from "./Typography";
import TempleHinduIcon from '@mui/icons-material/TempleHindu';
import React from "react"; // -----------------------------------------------------

// -----------------------------------------------------
const BhaktaPreview = ({ bhakta }) => {
  return (
    
      <Box sx={{"marginBottom":2}}>
        {false && <FlexBox alignItems="center" gap={1} mb={1}>
          <TempleHinduIcon/>
          <H6 fontSize={12}>Office</H6>
        </FlexBox>}

        <Paragraph fontSize={10} fontWeight={500} lineHeight={1.8}>
            {bhakta.preview}
        </Paragraph>
        {false && <Paragraph fontSize={10} fontWeight={500} lineHeight={1.8}>
          {bhakta.about[Object.keys(bhakta.about)[0]][0].replace( /(<([^>]+)>)/ig, '').substring(0, 200) + "..."}
        </Paragraph>}
      </Box>

    
  );
};

export default BhaktaPreview;
