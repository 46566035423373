import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Card, Grid, Tab, Typography, useMediaQuery } from "@mui/material";
//import FlexBox from "../flexbox/FlexBox";
//import FlexBetween from "../flexbox/FlexBetween";
//import { H2, H4, H5,  Small, Span, Tiny} from "../Typography";


export default function EventsTable({}) {
  const events = [
    {
      "location": "Muttam",
      "apr-dec":[
        {"name":"*Abhishekam+Bhajana (d)"},
        {"name":"*Punarvasu archana (m)"},
        {"name":"*17-25 Apr Navaaha Ramayana Paryana(9days)"},
        {"name":"*3-5 Jun Varshikotsavam(& Rukimini Kalyanam-3days)"},
        {"name":"*2-3 Jul Madhavendra Saraswathi Aaradhana & Radha kalyanam"},
        {"name":"*24 Aug Akhanda Vishnu Sahasranaamam Paryana(108)"},
        {"name":"*28 Sep-Bodhendra Aradhana (Ekadaashi namasankritana)"},
        {"name":"12-21 Dec Bhagvatha Sapthaham, Homam, Bhajana(10days)"},
        {"name":"Oct: Land purchase for Muttam extension", "milestone": true},
        {"name":"27 Dec Mahaperiyava Aradhana"},
        {"name":"30-31 Dec Guru Parikrama  (2days)", "milestone": true},
        {"name":"Dec-Muttam Bathroom for 10 Capacity ( Container) Setup"},
      ],
      "jan-apr": [
        {"name":"*Abhishekam+Bhajana (d)"},
        {"name":"*Punarvasu archana (m)"},
        {"name":"Jan-Mar-Extensions, Security Fence, Pathway Construction 90%"},
        {"name":"Apr-Navaham Ramayana Parayana(9days)"}
      ],
      "may-jul": [
        {"name":"Abhishekam+Bhajana (d)"},
        {"name":"Punarvasu archana (m)"},
        {"name":"Stabilize the Setup and Track Maintanenance"},
        {"name":"Maha Periayva Jayanthi"},
        {"name":"Bodhendral Varshikostava"},
        {"name":"Madhevendra Aradhane"}
      ]
    },
    {
      "location": "Goshala",
      "apr-dec":[
        {"name":"*GoShala Planning"},
        {"name":"*Jun-Readiness for Bhoomi pooja"},
        {"name":"*12 Jul - Bhoomi Pooja Complete"},
        {"name":"*Sep -Construction start to accommodate 40+Cows", "milestone": true}
      ],
      "jan-apr": [
        {"name":"GoShala Construction 20-30% (40+ Cows capacity)"},
      ],
      "may-jul": [
        {"name":"*GoShala Construction 40-50% (40+ Cows)"}
      ]
    },
    {
      "location": "Veda Patashala",
      "apr-dec":[
        {"name":"*Design & Planning for  Vadhyar House"},
        {"name":"*1-Jul VedaPatshala Foundation Complete", "milestone": true},
        {"name":"*12-Jul Vadhyar House  Construction Start", "milestone": true},
        {"name":"*12-Jul Patashala Start @ Muttam(Temp)", "milestone": true},
        {"name":"*12 Jul 28Aug Avahanati Homama(48days) and ended End of Aug"},
        {"name":"*Oct-Dec - Go Around Village around  Permabur  (25KM radius) for Patashala kids"},
        {"name":"*Initial Panning for  Temp Veda Patashala Start @ Muttam", "milestone": true}
      ],
      "jan-apr": [
        {"name":"Daily  Avahanti homam @muttam for Patashala"},
        {"name":"*Jan-Apr-Patashala Construction(80%)"},
      ],
      "may-jul": [
        {"name":"Daily  Avahanti homam @muttam for Patashala"},
        {"name":"*Apr/May Veda Patashala complete/Gruha Praveshsam", "milestone": true},
        {"name":"*Ganapathi homam to start monthly (m)"},
        {"name":"*Extension of  Bhumi/land Check"}
      ]
    },
    {
      "location": "Under Priviledged Support & Annadanam",
      "apr-dec":[
        {"name":"*Educational support 4+ people"},
        {"name":"*Medical Support for 5+ people"}
      ],
      "jan-apr": [{
        "name":"*Assess Toilets in Govt School/Hospital @ Perambur"
      },
      {
        "name":"Plan/Design for Annadanam."
      }],
      "may-jul": [
        {"name":"Constrcution for  Annadanam place", "milestone": true},
            {"name":"Bathroom constructions @ Perambur Govt school", "milestone": false}
        ]
    },
    {
      "location": "Nandanavanam/Canal/River/Lake",
      "apr-dec": [],
      "jan-apr":[
        {"name":"*Design/plan consturcting steps for pond, Kaveri River with karangal."},
        {"name":"*Design Plantation around pond/Muttam/Patashala."}],
      "may-jul":[
        {"name":"*Discuss with Villagers & Govt to get Permission."},
        {"name":"*Start plantation along with cultivation  of Vegetables."}
      ]
    },
    {
      "location": "Temple",
      "apr-dec":[
        {"name":"*Design Planning discussions for Raja Gopuram,Obtain Govt Permissions"},
        {"name":"*12-Jul Bhoomi Pooja done", "milestone": true},
        {"name":"*Aug - Boring Setup done"},
        {"name":"*Aug- Raja Gopuram Construcution  completed to Foundation level"},
        {"name":"*Oct-Dec- Planning Start  for Kamakshi+Adishakara Charya construction in Karangal"}
      ],
      "jan-apr": [
        {"name":"*Kamakshi/Adishankara  Readiness 80%"},
        {"name":"*80% Rajagopuram complete"},
        {"name":"*80%Compoundwall construction complete"}
      ],
      "may-jul": [
        {"name":"*May/Jun- Raja gopuram Work completed /Kumabiskeham", "milestone": true},
        {"name":"*May/Jun- Kamahkshi & Adishankar  Kumbahishekam", "milestone": true},
        {"name":"*May/Jun - Compound wall completion around Temple", "milestone": true}
      ]
    },
    {
      "location": "Ucchishta Bhumi",
      "apr-dec":[
        {"name":"*Design Planning discussions,Obtain Village Permissions"},
        {"name":"*Oct-Dec - Bore Setup done , Pooja Room Bhoom Pooja Complete"},
      ],
      "jan-apr": [
        {"name":"10% Construction complete"}
      ],
      "may-jul": [
        {"name":"60% Construction complete"}
      ]
    }
  ];
  const events2 = [
    {
      "location": "Muttam",
      "apr-jun":[
        {"name":"*Abhishekam+Bhajana (d)"},
        {"name":"*Punarvasu archana (m)"},
        {"name":"*17-25 Apr Navaaha Ramayana Paryana(9days)"},
        {"name":"*3-5 Jun Varshikotsavam(& Rukimini Kalyanam-3days)"}
      ],
      "jul-sep": [
        {"name":"*Abhishekam+Bhajana (d)"},
        {"name":"*Punarvasu archana (m)"},
        {"name":"*2-3 Jul Madhavendra Saraswathi Aaradhana & Radha kalyanam"},
        {"name":"*24 Aug Akhanda Vishnu Sahasranaamam Paryana(108)"},
        {"name":"*28 Sep-Bodhendra Aradhana (Ekadaashi namasankritana)"},
        {"name":"*Sep Extensions: Construction  Start (Kitchen & Container Bathrooms)", "milestone": true}
      ],
      "oct-dec": [
        {"name":"Abhishekam+Bhajana (d)"},
        {"name":"Punarvasu archana (m)"},
        {"name":"12-21 Dec Bhagvatha Sapthaham, Homam, Bhajana(10days)"},
        {"name":"27 Dec Mahaperiyava Aradhana"},
        {"name":"30-31 Dec Guru Parikrama  (2days)", "milestone": true},
        {"name":"Oct: Land purchase for Muttam extension", "milestone": true},
        {"name":"Extensions Construction Complete (Goshala start, Kitchen, Bathroom, Pathway , Vehcile Stand, Generator, Place for 20+ people to stay)",
        "milestone": true}
      ],
      "jan-mar":[
        {"name":"*Abhishekam+Bhajana (d)"},
        {"name":"*Punarvasu archana (m)"},
        {"name":"*Jan-Mar - Muttam Security  Fence & Finish"}
      ],
      "apr":[
        {"name":"*Abhishekam+Bhajana (d)"},
        {"name":"*Punarvasu archana (m)"},
        {"name":"*Stabilze & Continue", "milestone": true}
      ]
    },
    {
      "location": "Goshala",
      "apr-jun":[
        {"name":"*GoShala Planning"},
        {"name":"*Jun-Readiness for Bhoomi pooja"}
      ],
      "jul-sep": [
        {"name":"*12 Jul - Bhoomi Pooja Complete"},
        {"name":"*Sep -Construction start to accommodate 40+Cows", "milestone": true}
      ],
      "oct-dec": [
        {"name":"*GoShala Construction Continue (inc. house for  labor)"},
        {"name":"*Oct-Nov Setup Initial Goshala & Move from Muttam"}
      ],
      "jan-mar":[
        {"name":"GoShala Construction 70-80%Completed"}
      ],
      "apr":[
        {"name":"*Apr/May Goshala complete/Gruha Praveshsam", "milestone": true}
      ]
    },
    {
      "location": "Veda Patashala",
      "apr-jun":[
        {"name":"*Design & Planning for  Vadhyar House"},
        {"name":"*Initial Panning for  Temp Veda Patashala Start @ Muttam", "milestone": true}
      ],
      "jul-sep": [
        {"name":"*1-Jul VedaPatshala Foundation Complete", "milestone": true},
        {"name":"*12-Jul Vadhyar House  Construction Start", "milestone": true},
        {"name":"*12-Jul Patashala Start @ Muttam(Temp)", "milestone": true},
        {"name":"*12 Jul 28Aug Avahanati Homama(48days) and ended End of Aug"},
        {"name":"*12 Jul Start Patashala Teachers's houe construction", "milestone": true}
      ],
      "oct-dec": [
        {"name":"Daily  Avahanti homam @muttam for Patashala"},
        {"name":"*Oct-Dec - Continue Patashala Construction , Place for Homam, Samita Danam, Kids Playing area ..etc"},
        {"name":"*Oct-Dec - Continue Vadyar House Construction"},
        {"name":"*Oct-Dec - Go Around Village around  Permabur  (25KM radius) for Patashala kids"}
      ],
      "jan-mar":[
        {"name":"*Continue  daily  Avahanti homam @muttam for Patashala"},
        {"name":"*Jan-Mar- Complete Patashala Construction, pathway to Agraharam, All Utilities setup ( Max 90%)", "milestone": true}

      ],
      "apr":[
        {"name":"*Avahanti homam (m)"},
        {"name":"*Apr/May Veda Patashala complete/Gruha Praveshsam", "milestone": true},
        {"name":"*Apr/May- Vadhiyar house complete/Gruha Praveshsam (Potentially Start of Viswavasu Samvastra)", "milestone": true},
        {"name":"*Ganapathi homam to start monthly (m)"},
        {"name":"*Apr-Extension of  Bhumi/land Check"}
      ]
    },
    {
      "location": "Under Priviledged Support & Annadanam",
      "apr-jun":[],
      "jul-sep": [{
        "name":"Educational support for poor."
      },
      {
        "name":"Assess Toilets in Govt School/Hospital @ Perambur."
      }],
      "oct-dec": [
        {"name":"Start Planning discussion in Village Govt School  for bathrooms"}],
      "jan-mar":[{"name":"*Jan/Feb- Start  Constrcution of Toilets @ Govt School as per the assesment", "milestone": true}],
      "apr":[{"name":"Annadanam  model planning/design", "milestone": true},
            {"name":"Finish bathroom constructions @ Perambur Govt school", "milestone": false}]
    },
    {
      "location": "Nandanavanam/Canal/River/Lake",
      "apr-jun":[],
      "jul-sep": [],
      "oct-dec": [],
      "jan-mar":[
        {"name":"Design/plan consturcting steps for pond, Kaveri River with karangal. Place fence & plantation around pond. Future cleaning process setup."}],
      "apr":[
        {"name":"Discuss with Villagers & Govt to get Permission"},
        {"name":"Discuss plan for  plantation along with cultivation + Go Shala, Cow Feed design & irrigation system"}
      ]
    },
    {
      "location": "Temple",
      "apr-jun":[
        {"name":"*Design Planning discussions for Raja Gopuram,Obtain Govt Permissions"}
      ],
      "jul-sep": [
        {"name":"*12-Jul Raja Gopuram Bhoomi Pooja  Started", "milestone": true},
        {"name":"*Aug- Raja Gopuram Construcution  completed to Foundation level"}
      ],
      "oct-dec": [
        {"name":"*Oct Compund wall completion around the perimiter of Temple with Karangal", "milestone": true},
        {"name":"*Oct-Dec- Planning Start  for Kamakshi+Adishakara Charya construction in Karangal"}
      ],
      "jan-mar":[
        {"name":"*Jan-Feb Kamakshi/Adishankara kumbhabhishekam ( Balalayam done and the end the construction  work in 21 days with Kumabkishekam)", "milestone": true},
        {"name":"*Jan 80% Rajagopuram stonework completion & resume remaining work ( Kalasham, Painting, Brick work ..etc)","milestone": true}
      ],
      "apr":[
        {"name":"*Apr- Raja gopuram Work completed /Kumabiskeham (Potenitally Start of Viswavasu Samvastra or earlier in Feb?", "milestone": true}
      ]
    },
    {
      "location": "Ucchishta Bhumi",
      "apr-jun":[
        {"name":"*Design Planning discussions,Obtain Village Permissions"}
      ],
      "jul-sep": [
        {"name":"*12-Jul Bhoomi Pooja done", "milestone": true},
        {"name":"*Aug - Boring Setup done"}
      ],
      "oct-dec": [
        {"name":"*Oct-Dec - Construction  Start to accommodate  Pooja Room where  Bodhendra Sat , Bathrooms for Patashala  , Bridge  to connect Patashala  & Uchihta bhumi, Well, Hand Bore..etc",
        "milestone": true}
      ],
      "jan-mar":[
        {"name":"60% Construction complete"},
      ],
      "apr":[
        {"name":"Apr-Complete construction/Gruha Pravesham", "milestone": true},
      ]
    }
  ];
  return (
    <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
           
              <Box
                height="100%"
                alignItems="center"
                justifyContent="space-between"
                flexDirection="column"
              >
                 <Typography variant="h6" gutterBottom sx={{color:'white', fontWeight:'bold'}}>
                    Krodhi Samvatsara Events 2024-25
                </Typography>
                
              </Box>
            
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
          <TableContainer sx={{ paddingX:3, margin:0, maxHeight: 640, backgroundColor: '#faf8e3'}}>
      <Table stickyHeader sx={{  minWidth: 0, border: 0 }} aria-label="simple table">
        <colgroup>
          <col style={{width:'10%'}}/>
          <col style={{width:'30%'}}/>
          <col style={{width:'30%'}}/>
          <col style={{width:'30%'}}/>
        </colgroup>
        <TableHead>
          <TableRow>
          <TableCell sx={{padding:"1px", backgroundColor: '#faf8e3'}}><Typography variant="caption" gutterBottom sx={{ display: 'block', fontWeight:700 }}>Initiative</Typography></TableCell>
            <TableCell sx={{padding:"1px", backgroundColor: '#faf8e3'}}><Typography variant="caption" gutterBottom sx={{ display: 'block', fontWeight:700 }}>Apr-Dec</Typography></TableCell>
            <TableCell sx={{padding:"1px", backgroundColor: '#faf8e3'}}><Typography variant="caption" gutterBottom sx={{ display: 'block', fontWeight:700 }}>Jan-Apr</Typography></TableCell>
            <TableCell sx={{padding:"1px", backgroundColor: '#faf8e3'}}><Typography variant="caption" gutterBottom sx={{ display: 'block', fontWeight:700 }}>May-Jul</Typography></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {events.map((event, i) => (
            <TableRow
              key={i}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell scope="row" sx={{padding:"5px", }}>
              <Typography variant="caption" gutterBottom sx={{ display: 'block' }}>
                {event.location}
              </Typography>
              </TableCell>
              <TableCell component="th" scope="row" sx={{padding:"1px"}}>
                {event["apr-dec"].map(subEvent => (<Typography variant="caption" gutterBottom sx={{ display: 'block', fontWeight:(subEvent.milestone)? 700:300, color:(subEvent.milestone)?'#a84232':'black'}}>
                {subEvent.name}
              </Typography>))}
              </TableCell>
              <TableCell component="th" scope="row" sx={{padding:"1px"}}>
                {event["jan-apr"].map(subEvent => (<Typography variant="caption" gutterBottom sx={{ display: 'block', fontWeight:(subEvent.milestone)? 700:300, color:(subEvent.milestone)?'#a84232':'black'}}>
                {subEvent.name}
              </Typography>))}
              </TableCell>
              <TableCell component="th" scope="row" sx={{padding:"1px"}}>
                {event["may-jul"].map(subEvent => (<Typography variant="caption" gutterBottom sx={{ display: 'block', fontWeight:(subEvent.milestone)? 700:300, color:(subEvent.milestone)?'#a84232':'black' }}>
                {subEvent.name}
              </Typography>))}
              </TableCell>
            </TableRow>
          ))}
          <TableRow key="static1">
            <TableCell scope="row" sx={{padding:"1px"}}>
              <Typography variant="caption" gutterBottom sx={{ display: 'block' }}>
                Non-monetray Tasks
              </Typography>
              </TableCell>
              <TableCell colSpan={5} component="th" scope="row" sx={{padding:"1px"}}>
              <Typography variant="caption" gutterBottom sx={{ display: 'block' }}>
              1. Daily Rama Japam (4 am to 5 am IST thru link). 2. Rama namam writing (any time) 3. Daily Vishnu Sahasra Namam Recitation (anytime) 
                4. Daily Mahaperiyava Ashtottaram Recitation (anytime) 5. Daily Bodhendra Ashtottaram Recitation (anytime) 6. Bhakta Story Writing (anytime)
                7. Sloka Classes (Saturdays and Sundays). 8. Devatha Namavali writing (anytime)
              </Typography>
                
              </TableCell>
          </TableRow>
<TableRow key="static1">
            <TableCell scope="row" sx={{padding:"1px"}}>
            <Typography variant="caption" gutterBottom sx={{ display: 'block' }}>
              Guru Vakyam
            </Typography>
              </TableCell>
              <TableCell colSpan={5} component="th" scope="row" sx={{padding:"1px"}}>
              <Typography variant="caption" gutterBottom sx={{ display: 'block' }}>
              Guru Vakyam is the foundation for running Maha Yagnam (or this Trust). It provides direction to activities and defines rules and processes to follow in Dharmic Style. 
              Two batches were conducted for Guru Vakyam till date - each spanning multiple months outlining dharmic principles to follow as a human. 
              A third batch is poised to start during Uttarayanam (Jan/Feb-2025) to continue Guru Vakyam.
              </Typography>
                
              </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
          </Grid>

        </Grid>
    
  );
}
